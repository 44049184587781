/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 * with the License. A copy of the License is located at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 * OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

.soca-app-content {
    width: 100%;

    &.auth {
        margin-top: 100px;
        .auth-content-wrapper {
            .logo-container {
                text-align: center;
                padding: 20px;
                box-sizing: border-box;
                .logo {
                    max-width: 100px;
                }
            }
            .auth-content {
                padding: 30px 20px;
                box-sizing: border-box;
                border: 1px solid #ddd;
                border-radius: 5px;
                background-color: #ffffff;
                box-shadow: 2px 2px 2px #e1e1e1;
                .title {
                    text-align: center;
                    margin-bottom: 10px;
                }
                .subtitle {
                    text-align: center;
                    text-decoration: underline;
                    margin: 0 0 10px 0;
                }
                .description {
                    font-style: italic;
                    margin: 0;
                    text-align: center;
                }
                .actions {
                    button {
                        width: 100%;
                        span {
                            width: 100%;
                            display: inline-block;
                            text-align: center;
                        }
                    }
                }
                .error {
                    font-style: normal;
                    margin: 0;
                    text-align: center;
                    color: #ff0000;
                }
            }
            .copyright {
                text-align: center;
            }
        }
    }
}
